var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard-admin d-flex flex-column"},[_c('div',{staticClass:"dashboard-admin-content content"},[_c('div',{staticClass:"container grid-content menu0 px-0"},_vm._l((_vm.listFunction),function(value,index){return _c('div',{key:index,staticClass:"item-menu"},[_c('h4',{staticClass:"text-center font-weight-bold"},[_vm._v(_vm._s(value.title))]),_vm._l((value.value),function(item,ind){return _c('div',{key:ind,staticClass:"action-item"},[_c('router-link',{staticClass:"d-flex",attrs:{"to":{ name: item.routeName }}},[_c('img',{staticClass:"my-auto",attrs:{"src":item.icon,"alt":""}}),_c('p',{staticClass:"ml-2 my-auto"},[_vm._v(_vm._s(item.text))])])],1)})],2)}),0),(_vm.user_type == _vm.typeCheck['owner'] || _vm.user_type == _vm.typeCheck['cdea'])?_c('div',[(
          _vm.listNewAdminDashboard.data && _vm.listNewAdminDashboard.data.length > 0
        )?_c('div',{staticClass:"container px-3 py-3 mt-5 content-border"},[_c('label',{staticClass:"font-title"},[_vm._v("ニュース")]),_vm._l((_vm.listNewAdminDashboard.data),function(item,index){return _c('div',{key:index,staticClass:"content-bottom",on:{"click":function($event){return _vm.showDetail(item.id, index)}}},[_c('div',{staticClass:"content-bottom-title d-flex justify-content-between"},[_c('div',{staticClass:"title-left"},[_vm._v(_vm._s(item.news_title))]),_c('div',{staticClass:"title-right",staticStyle:{"min-width":"150px"}},[_vm._v(" 更新日："+_vm._s(_vm.moment(new Date(item.post_date)).format("YYYY/MM/DD"))+" ")])])])})],2):_vm._e(),_c('div',{staticClass:"d-flex justify-content-center position-relative m-auto pt-3"},[(
            _vm.listNewAdminDashboard.data && _vm.listNewAdminDashboard.last_page > 1
          )?_c('CPagination',{attrs:{"activePage":_vm.page,"pages":_vm.listNewAdminDashboard.last_page,"align":"center"},on:{"update:activePage":function($event){_vm.page=$event},"update:active-page":function($event){_vm.page=$event}}}):_vm._e()],1)]):_vm._e()]),_c('b-modal',{attrs:{"id":"detail-new","title":_vm.detailNew ? _vm.detailNew.news_title : '',"hide-footer":"","size":"lg"}},[(_vm.detailNew)?_c('div',[_c('div',[_c('div',{staticClass:"row d-flex align-items-center mb-4"},[_c('div',{staticClass:"col-md-2 col-12"},[_c('span',[_vm._v(_vm._s(_vm.moment(String(_vm.detailNew.post_date)).format("YYYY/MM/DD")))])])])]),_c('p',{staticStyle:{"height":"300px","overflow":"auto"},domProps:{"innerHTML":_vm._s(_vm.detailNew.news_content)}}),_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',{staticClass:"w-30 mr-3",attrs:{"disabled":_vm.listNewAdminDashboard.current_page === 1 && _vm.indexSelect === 0,"variant":"primary"},on:{"click":function($event){return _vm.previous()}}},[_vm._v(" 前のニュースへ ")]),_c('b-button',{staticClass:"w-30",attrs:{"disabled":_vm.listNewAdminDashboard.data &&
            _vm.listNewAdminDashboard.current_page ===
              _vm.listNewAdminDashboard.last_page &&
            _vm.indexSelect === _vm.listNewAdminDashboard.data.length - 1,"variant":"primary"},on:{"click":function($event){return _vm.next()}}},[_vm._v(" 次のニュースへ ")])],1)]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }