<template>
  <div class="dashboard-admin d-flex flex-column">
    <div class="dashboard-admin-content content">
      <div class="container grid-content menu0 px-0">
        <div
          class="item-menu"
          v-for="(value, index) in listFunction"
          :key="index"
        >
          <h4 class="text-center font-weight-bold">{{ value.title }}</h4>
          <div
            class="action-item"
            v-for="(item, ind) in value.value"
            :key="ind"
          >
            <router-link :to="{ name: item.routeName }" class="d-flex">
              <img :src="item.icon" alt="" class="my-auto" />
              <p class="ml-2 my-auto">{{ item.text }}</p>
            </router-link>
          </div>
        </div>
      </div>
      <div
        v-if="user_type == typeCheck['owner'] || user_type == typeCheck['cdea']"
      >
        <div
          v-if="
            listNewAdminDashboard.data && listNewAdminDashboard.data.length > 0
          "
          class="container px-3 py-3 mt-5 content-border"
        >
          <label class="font-title">ニュース</label>
          <div
            class="content-bottom"
            v-for="(item, index) in listNewAdminDashboard.data"
            :key="index"
            @click="showDetail(item.id, index)"
          >
            <div class="content-bottom-title d-flex justify-content-between">
              <div class="title-left">{{ item.news_title }}</div>
              <div class="title-right" style="min-width: 150px">
                更新日：{{
                  moment(new Date(item.post_date)).format("YYYY/MM/DD")
                }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="d-flex justify-content-center position-relative m-auto pt-3"
        >
          <CPagination
            v-if="
              listNewAdminDashboard.data && listNewAdminDashboard.last_page > 1
            "
            :activePage.sync="page"
            :pages="listNewAdminDashboard.last_page"
            align="center"
          />
        </div>
      </div>
    </div>
    <b-modal
      id="detail-new"
      :title="detailNew ? detailNew.news_title : ''"
      hide-footer
      size="lg"
    >
      <div v-if="detailNew">
        <div>
          <div class="row d-flex align-items-center mb-4">
            <div class="col-md-2 col-12">
              <span>{{
                moment(String(detailNew.post_date)).format("YYYY/MM/DD")
              }}</span>
            </div>
          </div>
        </div>
        <p
          v-html="detailNew.news_content"
          style="height: 300px; overflow: auto"
        ></p>
        <div class="d-flex justify-content-center">
          <b-button
            :disabled="
              listNewAdminDashboard.current_page === 1 && indexSelect === 0
            "
            variant="primary"
            class="w-30 mr-3"
            @click="previous()"
          >
            前のニュースへ
          </b-button>
          <b-button
            :disabled="
              listNewAdminDashboard.data &&
              listNewAdminDashboard.current_page ===
                listNewAdminDashboard.last_page &&
              indexSelect === listNewAdminDashboard.data.length - 1
            "
            variant="primary"
            class="w-30"
            @click="next()"
          >
            次のニュースへ
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { Api } from "../../utils/http-common";
import { Urls } from "../../utils/urls";
import { Constants } from "../../utils/constants";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import pageCreateMenu from "@/assets/img/page_creat.png";
import contentMenu from "@/assets/img/content.png";
import contentCreateMenu from "@/assets/img/content_create.png";

export default {
  name: "DashboardAdmin",
  data() {
    return {
      isLoading: false,
      detailNew: null,
      indexSelect: null,
      page: 1,
      direct: null,
      callFirstTime: false,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      user_type: localStorage.getItem(
        Constants.USER_TYPE_ADMIN +
          (this.$route.params.shopId
            ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
            : "_" + Constants.DOMAIN)
      ),
      typeCheck: Constants.USER_TYPE_DEFAULT,
      listFunction: [
        {
          title: "新規作成",
          value: [
            {
              icon: pageCreateMenu,
              text: "コンテンツページ作成",
              routeName: "create page",
            },
          ],
        },
        {
          title: "登錄",
          value: [
            {
              icon: contentCreateMenu,
              text: "コンテンツ作成",
              routeName: "Content Change",
            },
          ],
        },
        {
          title: "編集",
          value: [
            {
              icon: contentMenu,
              text: "コンテンツ管理",
              routeName: "list content",
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "listContent",
      "message",
      "success",
      "error",
      "listNewAdminDashboard",
    ]),
  },
  created() {
    const formData = {
      page: this.page,
      data: {
        shop_id: this.shop_id,
        limit: 5,
      },
    };
    this.getListContent(formData);
    if (
      this.user_type == this.typeCheck["owner"] ||
      this.user_type == this.typeCheck["cdea"]
    ) {
      this.getListNewAdminDashboard(formData);
    }
    let shopId = this.$route.params.shopId;
    if (!shopId) {
      this.listFunction.forEach((obj) => {
        obj.value.forEach((e) => {
          e.routeName += " domain";
        });
      });
    }
  },
  watch: {
    page() {
      this.getListNews(this.page);
    },
    listNewAdminDashboard() {
      if (this.listNewAdminDashboard.data.length && this.callFirstTime) {
        this.indexSelect =
          this.direct === "next"
            ? 0
            : this.listNewAdminDashboard.data.length - 1;
        this.detailNew = this.listNewAdminDashboard.data.filter(
          (item, index) => index === this.indexSelect
        )[0];
      } else {
        this.callFirstTime = true;
      }
    },
  },
  methods: {
    ...mapActions({ getListContent: "getListContent" }),
    ...mapActions({ getListNewAdminDashboard: "getListNewAdminDashboard" }),
    moment,
    logoutBtn() {
      this.isLoading = true;
      Api.adminRequestServer
        .post(`/${Urls.LOGOUT}`)
        .then((response) => {
          const { data } = response;
          const shop_id = this.shop_id;
          this.isLoading = false;
          if (data.success) {
            this.$toasted.success(data.message);
            this.$store.commit("set", ["success", false]);
            this.$store.commit("set", ["message", ""]);
            localStorage.removeItem(Constants.USER_TYPE_ADMIN);
            localStorage.removeItem(Constants.TOKEN_ADMIN);
            localStorage.removeItem(Constants.ADMIN_USER_INFO);
            if (this.$route.params.shopId) {
              this.$router.push({
                name: "login admin",
                params: { shopId: shop_id },
              });
            } else {
              this.$router.push({ name: "login admin domain" });
            }
          } else {
            this.$toasted.error(data.message);
            this.$store.commit("set", ["message", ""]);
            this.$store.commit("set", ["error", false]);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.$toasted.error(error.message);
          this.$store.commit("set", ["message", ""]);
          this.$store.commit("set", ["error", false]);
        });
    },
    createContent() {
      localStorage.setItem(Constants.NO_CONTENT, this.listContent.total + 1);
      this.$router.push({
        name: this.$route.params.shopId
          ? "Content Change"
          : "Content Change domain",
      });
    },
    loginUser() {
      this.isLoading = true;
      const shop_id = this.shop_id;
      const self = this;
      if (localStorage.getItem(Constants.TOKEN_USER)) {
        Api.userRequestServer
          .post(Urls.LOGOUT)
          .then((response) => {
            const { data } = response;
            if (data.success) {
              this.$store.commit("set", ["success", false]);
              this.$store.commit("set", ["message", ""]);
              localStorage.removeItem(Constants.USER_TYPE_USER);
              localStorage.removeItem(Constants.TOKEN_USER);
              localStorage.removeItem(Constants.NORMAL_USER_INFO);
              localStorage.removeItem(Constants.LIST_SLAG);
            } else {
              this.$toasted.error(data.message);
              this.$store.commit("set", ["message", ""]);
              this.$store.commit("set", ["error", false]);
            }
          })
          .catch((error) => {
            this.$toasted.error(error.message);
            this.$store.commit("set", ["message", ""]);
            this.$store.commit("set", ["error", false]);
          });
      }
      setTimeout(function () {
        self.isLoading = false;
        self.$router.push({
          name: "library",
          params: { shopId: shop_id },
        });
      }, 2000);
    },
    getListNews(page) {
      if (page === undefined) {
        page = this.page;
      }
      const formData = {
        page: this.page,
        data: {
          shop_id: this.shop_id,
          limit: 5,
        },
      };
      this.getListNewAdminDashboard(formData);
    },
    previous() {
      this.direct = "previous";
      this.indexSelect -= 1;
      this.detailNew = this.listNewAdminDashboard.data.filter(
        (item, index) => index === this.indexSelect
      )[0];
      if (!this.detailNew) {
        this.page -= 1;
        this.getListNews(this.page);
      }
    },
    next() {
      this.direct = "next";
      this.indexSelect += 1;
      this.detailNew = this.listNewAdminDashboard.data.filter(
        (item, index) => index === this.indexSelect
      )[0];
      if (!this.detailNew) {
        this.page += 1;
        this.getListNews(this.page);
      }
    },
    showDetail(id, index) {
      this.indexSelect = index;
      this.detailNew = this.listNewAdminDashboard.data.filter(
        (item) => item.id === id
      )[0];
      this.$bvModal.show("detail-new");
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-admin {
  background: white;
  min-height: calc(100vh - 150px);
  &-header {
    border: 1px solid #c4c4c4;
    background: #f5f5f5;
  }
  &-content {
    // padding: 40px 150px;
    .content-top {
      width: 100%;
      height: 400px;
      border-radius: 10px;
      padding: 20px;
      &-title {
        font-size: 1.714em;
        line-height: 1.45em;
      }
      &-list {
        &-item {
          margin-bottom: 20px;
          a {
            color: black;
            &:hover {
              text-decoration: none;
            }
          }
          &-icon {
            img {
              width: 50px;
              height: 50px;
            }
          }
          &-text {
            font-size: 13px;
            font-weight: bold;
            color: #3e3e3e;
          }
        }
      }
    }
    .content-bottom {
      margin-bottom: 20px;
      margin-top: 20px;
      border: 2px solid #c4c4c4;
      width: 100%;
      border-radius: 10px;
      &-title {
        padding: 10px;
        font-size: 16px;
        font-weight: 600;
      }
      &-content {
        padding: 20px;
      }
    }
    .content-bottom:hover {
      cursor: pointer;
    }
    .content-border {
      background-color: #fff;
      width: 100%;
      border-radius: 10px;
    }
  }
  @media (max-width: 450px) {
    &-content {
      // padding: 30px 10px;
      .content-top {
        &-list {
          &-item {
            &-text {
              font-size: 12px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
</style>
